import React from "react"
import SEO from "../components/seo"
import styled from "styled-components";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  background-color: var(--Primary-color-dark);
  display: flex;
  flex-grow: 1;
`;

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <PageWrapper>
			<h1 style={{ width: "fit-content", margin: "0 auto", marginTop: '2%', color: 'white' }}>Error 404: NOT FOUND</h1>
    </PageWrapper>
  </>
)

export default NotFoundPage
